import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import {
  fetchSolr,
  solrResetItems,
  setLastListPath,
} from "../redux/actions/SolrActions"
import { createCategorieBreadCrumb } from "../utils"
import Page from "./Page"
import Results from "./results/Results"
import SubCats from "../components/UI/Categories/SubCats"

const Categorie = ({
  data,
  location,
  items,
  loading,
  dispatch,
  currentLocation,
  path,
}) => {
  const { tid: node, nid: ville } = data.categoriesVillesJson
  const crumbs = createCategorieBreadCrumb(node)
  crumbs.push({
    path: path,
    title: ville.title,
  })
  useEffect(() => {
    if (currentLocation.pathname !== location.pathname) {
      dispatch(solrResetItems())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined" && !loading && !items) {
      if (node.parentTid === "0") {
        dispatch(fetchSolr(false, false, ville.nid, node.tid, true))
      } else {
        dispatch(fetchSolr(false, node.tid, ville.nid, false, true))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, items, node.tid, node.parentTid, dispatch])

  useEffect(() => {
    if (!loading && items) {
      dispatch(setLastListPath(location.pathname))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, items])

  const description =
    "Retrouvez toutes les entreprises " +
    node.name +
    " situées à " +
    ville.title +
    " sur Plan.nc. Trouvez les numéros de téléphone et adresses des entreprises de votre quartier ou de votre ville sur Plan.nc"
  return (
    <Page
      crumbs={crumbs}
      title={node.name + " | " + ville.title}
      location={location}
      description={description}
      filters={node.parentTid !== "0"}
      showCrumbs={true}
    >
      {node.parentTid === "0" && <SubCats />}
      {node.parentTid !== "0" && <Results loading={loading} results={items} />}
    </Page>
  )
}
const mapStateToProps = state => ({
  items: state.solr.items,
  loading: state.solr.loading,
  currentLocation: state.global.location,
})

export default connect(mapStateToProps)(Categorie)

export const categorieQuery = graphql`
  query CategorieVilleTemplate($id: String!) {
    categoriesVillesJson(id: { eq: $id }) {
      nid {
        nid
        title
      }
      tid {
        tid
        name
        description
        parentTid
        url
        parentCategorie {
          name
          url
          parentCategorie {
            name
            url
            parentCategorie {
              name
              url
            }
          }
        }
      }
    }
  }
`
