import React, { useState, useEffect } from "react"
import { Text, Box, Button, Flex } from "rebass/styled-components"
import { connect } from "react-redux"
import { fetchSolr } from "../../../redux/actions/SolrActions"
import { useStaticQuery, graphql, navigate } from "gatsby"
import forEach from "lodash/forEach"
import isUndefined from "lodash/isUndefined"
import find from "lodash/find"
import orderBy from "lodash/orderBy"

const FilterLine = ({ name, count, i }) => (
  <Flex
    alignItems="center"
    width="100%"
    px={2}
    py={2}
    ml={-1}
    pl={1}
    sx={{ "&:hover": { backgroundColor: "grayLight", borderRadius: "4px" } }}
  >
    <Flex
      minWidth="30px"
      width="30px"
      height="30px"
      bg="primary"
      color="white"
      sx={{ borderRadius: "30px" }}
      justifyContent="center"
      alignItems="center"
    >
      <Text fontWeight="bold">{count}</Text>
    </Flex>
    <Text pl={2} textAlign="left">
      {name}
    </Text>
  </Flex>
)

const SubCats = ({
  items,
  lastCategorie,
  lastCategorieParent,
  lastCity,
  facetsCategories,
  dispatch,
  categorieParent,
  word,
}) => {
  const data = useStaticQuery(graphql`
    query subCatQuery {
      allCategoriesJson(filter: { parentTid: { ne: "0" } }) {
        nodes {
          name
          tid
          url
          parentTid
        }
      }
    }
  `)
  const categoriesJson = data.allCategoriesJson.nodes
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const categoriesFacets = []
    forEach(facetsCategories, function(count, tid) {
      const categorie = find(categoriesJson, function(c) {
        return c.tid === tid
      })
      if (!isUndefined(categorie)) {
        categoriesFacets.push({
          tid: tid,
          count: count,
          name: categorie.name,
          url: categorie.url,
          parentTid: categorie.parentTid,
        })
      }
    })
    if (lastCategorie) {
      setCategories(
        orderBy(
          categoriesFacets.filter(c => c.tid === lastCategorie),
          ["name"],
          ["asc"]
        )
      )
    } else {
      setCategories(orderBy(categoriesFacets, ["name"], ["asc"]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])
  const filterCategorie = categorie => {
    if (word === "") {
      navigate(categorie.url)
    } else {
      dispatch(fetchSolr(word, categorie.tid, lastCity, lastCategorieParent))
    }
  }

  if (items.length === 0 || !items) {
    return <></>
  }
  return (
    <Box>
      <Flex mx={-2} flexDirection={["column", "column", "row"]}>
        <Box px={2}>
          <Flex flexWrap="wrap">
            {categories
              .filter(c => c.parentTid === categorieParent)
              .map((categorie, i) => (
                <Button
                  key={i}
                  width={[1, 1, 1 / 2]}
                  variant="ninja"
                  onClick={() => filterCategorie(categorie)}
                  pl={i % 2 !== 0 ? [0, 0, 2] : [0]}
                  sx={
                    i % 2 !== 0
                      ? {
                          borderLeftColor: "muted",
                          borderLeftWidth: ["0", "0", "1px"],
                          borderLeftStyle: "solid",
                        }
                      : {}
                  }
                >
                  <FilterLine {...categorie} i={i} />
                </Button>
              ))}
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
const mapStateToProps = state => ({
  ...state.solr,
  location: state.global.location,
})
export default connect(mapStateToProps)(SubCats)
